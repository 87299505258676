import React from "react"

import Layout from "../components/layout"
import Payment from "../components/marketing/payment"
import SEO from "../components/seo"

const PaymentPage = (props) => (
  <Layout>
    <SEO title="Payment" />
    <Payment {...props}/>
  </Layout>
)

export default PaymentPage
