import React, { Component, useContext } from "react"
import { Link, navigate } from 'gatsby'
import { Collapse, message } from "antd"
import _ from "lodash";
import InputMask from 'react-text-mask'
import moment from 'moment'
import ReactModal from 'react-modal'
import { match } from '@reach/router/lib/utils';
import base64 from "base-64"

import Header from "./header"

import headerLogo from "../../images/curbside-logo.png"
import infoIcon from "../../images/infoicon.svg"
import lockIcon from "../../images/lockicon.svg"

import roofingIcon from "../../images/roofing.svg"
import roofingWhiteIcon from "../../images/roofing-white.svg"
import cardboardIcon from "../../images/cardboard.svg"
import cardboardWhiteIcon from "../../images/cardboard-white.svg"
import furnitureIcon from "../../images/furniture.svg"
import furnitureWhiteIcon from "../../images/furniture-white.svg"
import dirtsandIcon from "../../images/dirtsand.svg"
import dirtsandWhiteIcon from "../../images/dirtsand-white.svg"
import asphaltIcon from "../../images/asphalt.svg"
import asphaltWhiteIcon from "../../images/asphalt-white.svg"

import appliancesIcon from "../../images/appliances.svg"
import appliancesWhiteIcon from "../../images/appliances-white.svg"
import concreteIcon from "../../images/concrete.svg"
import concreteWhiteIcon from "../../images/concrete-white.svg"
import sheetrockIcon from "../../images/sheetrock.svg"
import sheetrockWhiteIcon from "../../images/sheetrock-white.svg"
import brickIcon from "../../images/brick.svg"
import brickWhiteIcon from "../../images/brick-white.svg"
import tileIcon from "../../images/tile.svg"
import tileWhiteIcon from "../../images/tile-white.svg"

import stoneIcon from "../../images/stoneicon.svg"
import stoneWhiteIcon from "../../images/stoneicon-white.svg"
import glassIcon from "../../images/glassicon.svg"
import glassWhiteIcon from "../../images/glassicon-white.svg"
import metalIcon from "../../images/metalicon.svg"
import metalWhiteIcon from "../../images/metalicon-white.svg"
import woodIcon from "../../images/woodicon.svg"
import woodWhiteIcon from "../../images/woodicon-white.svg"
import plasticIcon from "../../images/plasticicon.svg"
import plasticWhiteIcon from "../../images/plasticicon-white.svg"

import putrescibleIcon from "../../images/putrescibleicon.svg"
import putrescibleWhiteIcon from "../../images/putrescibleicon-white.svg"
import greensIcon from "../../images/greensicon.svg"
import greensWhiteIcon from "../../images/greensicon-white.svg"
import mixedIcon from "../../images/mixedicon.svg"
import mixedWhiteIcon from "../../images/mixedicon-white.svg"
import masonryIcon from "../../images/masonryicon.svg"
import masonryWhiteIcon from "../../images/masonryicon-white.svg"
import AddCardknoxForm from './partials/addCardknox'
import {
  SquarePaymentForm,
  CreditCardNumberInput,
  CreditCardExpirationDateInput,
  CreditCardPostalCodeInput,
  CreditCardCVVInput,
  CreditCardSubmitButton,
  Context
} from "react-square-payment-form";
import "react-square-payment-form/lib/default.css";
import modalcloseIcon from "../../images/cancel.svg"

const load = require("load-stripe")
const { Panel } = Collapse;
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME
const stripePublishKey = process.env.REACT_APP_STRIPE_KEY
const LOGIN_URL = process.env.LOGIN_URL

const phoneNumberMask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
]

const cardNumberMask = [
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
]
const expDateMask = [
  /[0-9]/,
  /\d/,
  "/",
  /\d/,
  /\d/
]
const cvvMask = [
  /[0-9]/,
  /\d/,
  /\d/,
  /\d/
]

const MyCustomButton = ({ disabled, validate, state }) => {
  const context = useContext(Context);
 
  const handleSubmit = evt => {
    evt.preventDefault();
    validate()
    const { err } = state
    console.log(Object.keys(err).length)
    if(Object.keys(err).length === 0) {
      context.onCreateNonce()
    }
   }
 
   return (
     <button disabled={disabled} onClick={handleSubmit} className="btn btn-complete">Complete Booking</button>
   );
 }

export const formatPhoneNumber = (s) => {
    var s2 = ("" + s).replace("-", "").replace(/\D/g, '');
    if (isNaN(s2)) {
        return "";
    } else {
        var length = s2.length;
        var m = null;

        if (length > 10) {
            s2 = s2.slice(0, 10);
            length = s2.length;
        }

        switch (length) {
            case 10:
                m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
                return (!m) ? s2 : + m[1] + "-" + m[2] + "-" + m[3];
            case 9:
                m = s2.match(/^(\d{3})(\d{3})(\d{3})$/);
                return (!m) ? s2 : + m[1] + "-" + m[2] + "-" + m[3];
            case 8:
                m = s2.match(/^(\d{3})(\d{3})(\d{2})$/);
                return (!m) ? s2 : + m[1] + "-" + m[2] + "-" + m[3];
            case 7:
                m = s2.match(/^(\d{3})(\d{3})(\d{1})$/);
                return (!m) ? s2 : + m[1] + "-" + m[2] + "-" + m[3];
            case 6:
                m = s2.match(/^(\d{3})(\d{3})$/);
                return (!m) ? s2 : + m[1] + "-" + m[2];
            case 5:
                m = s2.match(/^(\d{3})(\d{2})$/);
                return (!m) ? s2 : + m[1] + "-" + m[2];
            case 4:
                m = s2.match(/^(\d{3})(\d{1})$/);
                return (!m) ? s2 : + m[1] + "-" + m[2];
            case 3:
                m = s2.match(/^(\d{3})(\d)$/);
                return (!m) ? s2 : + m[1];
            default:
                return s2;
        }
    }
}

class PaymentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstStepData: {},
      addedmaterials: [],
      specialInstructions: '',
      name: '',
      email: '',
      phone: '',
      theirName: '',
      theirPhone: '',
      creditCard : '',
      cvv: '',
      expiry: '',
      cardnumber: '',
      firstname: '',
      lastname: '',
      nameOncard: '',
      securityCode: '',
      expDate: '',
      couponCode: '',
      basePrice: 0,
      estimated: 0,
      totalPrice: 0,
      heavyMaterials: ['Concrete', 'Mixed Masonry', 'Stone', 'Brick', 'Dirt/Sand', 'Asphalt', 'Roofing', 'Tile', 'Glass'],
      deliveryFee: 0,
      pointOfContact: false,
      permit: false,
      couponCodeDisabled: false,
      discount: 0,
      terms: false,
      openModal: false,
      err: {},
      loadervisible: false,
      paymentSectionForMobile: true,
      paymentGatewayUsed: 'stripe',
      squareApplicationId: '',
      squareLocationId: '',
      xKey: '',
      cardNumberIsValid: false
    };
  }

  componentDidMount = async() => {
    var url = new URL(window.location.href);
    var c = url.searchParams.get("id");
    let isData = false
    if (this.props.pageContext.matchPath) {
      const result = match(this.props.pageContext.matchPath, this.props.location.pathname);
      if (result && result.params) {
        if (result && result.params.id !==_.get(this.props, 'location.state.data.randomString', '')) {
          const id = result.params.id
          const mailCredential = {
            randomString: id
          }
          await fetch(`${HOSTNAME}/api/quotes/getquoteData`,{
            method:'POST',
            headers:{
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(mailCredential)
          }).then((res) =>  {
            return res.json()
          }).then((data) => {
            if(data.error) {
              navigate(`/curbside-now/new-york`);
            } else {
              isData =  true
              this.setState({ firstStepData: data.data}, async() => {
              await fetch(`${HOSTNAME}/api/sessions/getZipPricing`,{
                method:'GET'
              }).then((res) =>  {
                return res.json()
              }).then((data2) => {
                if(data2.error) {
                } else {
                  const zipcode = _.find(data2.data, (places) => {
                    return places.zipcode === this.state.firstStepData.zipcode
                  })
                  if(zipcode) {
                    const firstStepData = this.state.firstStepData
                    const containersize = _.lowerCase(firstStepData.containerSize).replace(/ /g,'')
                    if(containersize && containersize !== '1/2yard' && containersize !== 'liveload') {
                      firstStepData.zipexists = true
                      firstStepData.basePrice = zipcode[containersize]
                      firstStepData.tons = zipcode[containersize + "limit"]
                      this.setState({firstStepData})
                      this.priceCalculation()
                    }
                  }
                }
                //message.success("successfully submited")
                //this.resetState()
              }).catch((err) => {
                navigate(`/curbside-now/new-york`);
              });
            })
            }
            //message.success("successfully submited")
            //this.resetState()
          }).catch((err) => {
            navigate(`/curbside-now/new-york`);
          });
          // navigate(`/curbside-now/new-york`);
        }
      } else {
        navigate(`/curbside-now/new-york`);
      }
    } else {
      navigate(`/curbside-now/new-york`);
    }

    if (_.get(this.props, 'location.state.data.email', '') !== '') {
      this.setState({ firstStepData: _.get(this.props, 'location.state.data', '') },async()=>{
        await fetch(`${HOSTNAME}/api/sessions/getZipPricing`,{
          method:'GET'
        }).then((res) =>  {
          return res.json()
        }).then((data2) => {
          if(data2.error) {
          } else {
            const zipcode = _.find(data2.data, (places) => {
              return places.zipcode === this.state.firstStepData.zipcode
            })
            if(zipcode) {
              const firstStepData = this.state.firstStepData
              
              const containersize = _.lowerCase(firstStepData.containerSize).replace(/ /g,'')
              if(containersize && containersize !== '1/2yard' && containersize !== 'liveload') {
                firstStepData.zipexists = true
                firstStepData.basePrice = zipcode[containersize]
                firstStepData.tons = zipcode[containersize + "limit"]
                this.setState({firstStepData})
                this.priceCalculation()
              }
              
            }
          }
          //message.success("successfully submited")
          //this.resetState()
        }).catch((err) => {
          navigate(`/curbside-now/new-york`);
        });
        this.setState({ email: _.get(this.state, 'firstStepData.email', '') })
        this.priceCalculation()
        // console.log(this.state.firstStepData)
      })
    } else if(isData) {
      this.setState({ email: _.get(this.state, 'firstStepData.email', '') })
        this.priceCalculation()
    }

    const getCompany = await fetch(`${HOSTNAME}/api/companies/getCompanyDetailByUrl?companyurl=${process.env.REACT_APP_ORIGIN}`,{
      method:'GET'
    })
    const companyDetails = await getCompany.json()
    this.setState({
      paymentGatewayUsed: _.get(companyDetails, "data.paymentGatewayUsed", ""),
      squareApplicationId: _.get(companyDetails, "data.squareApplicationId", ""),
      squareLocationId: _.get(companyDetails, "data.squareLocationId", ""),
      xKey: _.get(companyDetails, "data.cardKnoxIFieldKey", "")
    })
  }

  priceCalculation () {
    let basePrice = 0
    let estimated = 0
    let totalPrice = 0
    // let deliveryFee = 0
    const { heavyMaterials } = this.state
    let firstStepData = _.get(this.state, 'firstStepData', {})
    let isHeavyM = false
    _.get(this.state, 'addedmaterials', []).map((materials)=>{
      if (heavyMaterials.indexOf(materials) !== -1) {
        isHeavyM = true
      }
    })
    if(firstStepData.zipexists === true && !isHeavyM) {
      basePrice = this.state.firstStepData.basePrice
      
      let permit = 0
      if(_.get(firstStepData, 'placement', '') === "On Street" && _.get(firstStepData, 'containerSize', '') !== 'Live Load') {
        permit = 54.44
        this.setState({ permit: true })
      } else {
        this.setState({ permit: false })
      }
      const val = parseFloat(basePrice) + parseFloat(permit)
      estimated = (parseFloat(val)*8.875)/100
      totalPrice = parseFloat(estimated) + parseFloat(basePrice) +  parseFloat(permit)
      this.setState({ basePrice: basePrice, estimated: estimated.toFixed(2),
        totalPrice: totalPrice.toFixed(2) })
    } else {
      let arr = [
        {tons:'2', yard:'10 Yard', heavyPrice: 900, lowPrice: 600, price: 120},
        {tons:'4', yard:'20 Yard', heavyPrice: 1400, lowPrice: 850, price: 120},
        {tons:'6', yard:'30 Yard', heavyPrice: 950, lowPrice: 950, price: 120},
        {tons:'3', yard:'15 Yard', heavyPrice: 1200, lowPrice: 750, price: 120},
        {tons:'2', yard:'Live Load', heavyPrice: 85, lowPrice: 65, price: 120},
        {tons:'2', yard:'1/2 Yard', heavyPrice: 48, lowPrice: 35, price: 120}
      ]
      if(_.get(firstStepData, 'county', '') === "New York" || _.get(firstStepData, 'county', '') === 'Westchester') {
        arr = [
          {tons:'2', yard:'10 Yard', heavyPrice: 900, lowPrice: 600, price: 120},
          {tons:'4', yard:'20 Yard', heavyPrice: 1400, lowPrice: 850, price: 120},
          {tons:'6', yard:'30 Yard', heavyPrice: 950, lowPrice: 950, price: 120},
          {tons:'3', yard:'15 Yard', heavyPrice: 1200, lowPrice: 750, price: 120},
          {tons:'2', yard:'Live Load', heavyPrice: 50, lowPrice: 50, price: 120},
          {tons:'2', yard:'1/2 Yard', heavyPrice: 35, lowPrice: 28, price: 120}
        ]
      } else if (_.get(firstStepData, 'county', '') === "Nassau") {
        arr = [
          {tons:'2', yard:'10 Yard', heavyPrice: 900, lowPrice: 475, price: 120},
          {tons:'4', yard:'20 Yard', heavyPrice: 1500, lowPrice: 675, price: 120},
          {tons:'5', yard:'30 Yard', heavyPrice: 775, lowPrice: 775, price: 120},
          {tons:'3', yard:'15 Yard', heavyPrice: 1100, lowPrice: 575, price: 120},
          {tons:'2', yard:'Live Load', heavyPrice: 50, lowPrice: 50, price: 120},
          {tons:'2', yard:'1/2 Yard', heavyPrice: 38, lowPrice: 28, price: 120}
        ]
      }
      if (_.get(firstStepData, 'containerSize', '') === '10 Yard') {
        const p = _.find(arr, (a) => { return a.yard === '10 Yard'})
          basePrice = _.get(p, 'lowPrice', 475)
        _.get(this.state, 'addedmaterials', []).map((materials)=>{
          if (heavyMaterials.indexOf(materials) !== -1) {
            basePrice =  _.get(p, 'heavyPrice', 475)
          }
        })
      }
  
      if (_.get(firstStepData, 'containerSize', '') === '15 Yard') {
        const p = _.find(arr, (a) => { return a.yard === '15 Yard'})
          basePrice = _.get(p, 'lowPrice', 475)
        _.get(this.state, 'addedmaterials', []).map((materials)=>{
          if (heavyMaterials.indexOf(materials) !== -1) {
            basePrice =  _.get(p, 'heavyPrice', 475)
          }
        })
      }
      if (_.get(firstStepData, 'containerSize', '') === '20 Yard') {
          const p = _.find(arr, (a) => { return a.yard === '20 Yard'})
          basePrice = _.get(p, 'lowPrice', 475)
        _.get(this.state, 'addedmaterials', []).map((materials)=>{
          if (heavyMaterials.indexOf(materials) !== -1) {
            basePrice = _.get(p, 'heavyPrice', 475)
          }
        })
      }
      if (_.get(firstStepData, 'containerSize', '') === '30 Yard') {
          const p = _.find(arr, (a) => { return a.yard === '30 Yard'})
          basePrice =  _.get(p, 'lowPrice', 475)
        // _.get(this.props, 'addedmaterials', []).map((materials)=>{
        //   console.log(materials, 'iiiii', heavyMaterials.indexOf(materials))
        //   if (heavyMaterials.indexOf(materials) !== -1) {
        //     basePrice = 1400
        //   }
        // })
      }
      if (_.get(firstStepData, 'containerSize', '') === '1/2 Yard') {
        const p = _.find(arr, (a) => { return a.yard === '1/2 Yard'})
          basePrice = _.get(p, 'lowPrice', 475) * _.get(firstStepData, 'live_load_yard', 0)
        _.get(this.state, 'addedmaterials', []).map((materials)=>{
          if (heavyMaterials.indexOf(materials) !== -1) {
            basePrice = _.get(p, 'heavyPrice', 475)
          }
        })
      }
      let priceperyard = 0
      if (_.get(firstStepData, 'containerSize', '') === 'Live Load') {
        const p = _.find(arr, (a) => { return a.yard === 'Live Load'})
          basePrice = _.get(p, 'lowPrice', 475) * _.get(firstStepData, 'live_load_yard', 0)
          priceperyard = _.get(p, 'lowPrice', 475)
        _.get(this.state, 'addedmaterials', []).map((materials)=>{
          if (heavyMaterials.indexOf(materials) !== -1) {
            priceperyard = _.get(p, 'heavyPrice', 475)
            basePrice = _.get(p, 'heavyPrice', 475) * _.get(firstStepData, 'live_load_yard', 0)
          }
        })
      }
      let permit = 0
      if(_.get(firstStepData, 'placement', '') === "On Street" && _.get(firstStepData, 'containerSize', '') !== 'Live Load') {
        totalPrice= (basePrice+54.44)
        permit = 54.44
        this.setState({ permit: true })
      } else {
        totalPrice = basePrice
        this.setState({ permit: false })
      }
  
      // if (_.get(firstStepData, 'containerSize', '') !== 'Live Load') {
      //   totalPrice = basePrice
      //   this.setState({ permit: false })
      // }
      estimated = (totalPrice*8.875)/100
      totalPrice= (totalPrice+estimated)
      if (_.get(firstStepData, 'containerSize', '') === 'Live Load') {
        totalPrice = (basePrice+250)
        estimated = (totalPrice*8.875)/100
        totalPrice = totalPrice + estimated
        this.setState({ truckingrate: 250 })
      }
  
      if (_.get(firstStepData, 'containerSize', '') === '1/2 Yard') {
        totalPrice = (basePrice+250)
        estimated = (totalPrice*8.875)/100
        totalPrice = totalPrice + estimated
        this.setState({ deliveryFee: 250 })
      }
  
      this.setState({
        basePrice,
        priceperyard,
        estimated: estimated.toFixed(2),
        totalPrice: totalPrice.toFixed(2)
      }, ()=>{
        this.forceUpdate()
      })
    } 
    
  }


  addMaterial(type) {
    const addedmaterials = this.state.addedmaterials
    if(addedmaterials.indexOf(type) !== -1) {
      const index = _.findIndex(addedmaterials, function(m) {
        return String(m) === String(type)
      })
      if(index !== -1) {
        addedmaterials.splice(index, 1)
      }
    } else {
      addedmaterials.push(type)
    }
    this.setState({ addedmaterials },()=>{
      this.priceCalculation()
      this.forceUpdate()
    })
  }

  handleChange (e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  nextDisabled (value) {
    let nextDisabled = true
    const { cardNumberIsValid, paymentGatewayUsed, email, firstname, lastname, phone, theirPhone, theirName, cardnumber, nameOncard, securityCode, expDate, terms } = this.state
    if (paymentGatewayUsed === 'stripe') {
      if(!this.state.pointOfContact) {
        if(theirName && theirPhone && email && firstname && lastname && phone && cardnumber && nameOncard && securityCode && expDate && terms) {
          nextDisabled = false
        } else {
          nextDisabled = true
        }
      } else {
        if (email && firstname && lastname && phone && cardnumber && nameOncard && securityCode && expDate && terms ) {
          nextDisabled = false
        } else {
          nextDisabled = true
        }
      }
      if(value) {
        return true
      } else {
        return nextDisabled
      }
    } else if (paymentGatewayUsed === 'cardKnox') {
      if(!this.state.pointOfContact) {
        if(theirName && theirPhone && email && firstname && lastname && phone && expDate && terms && cardNumberIsValid) {
          nextDisabled = false
        } else {
          nextDisabled = true
        }
      } else {
        if (email && firstname && lastname && phone && terms && expDate && cardNumberIsValid) {
          nextDisabled = false
        } else {
          nextDisabled = true
        }
      }
      if(value) {
        return true
      } else {
        return nextDisabled
      }
    } else {
      if(!this.state.pointOfContact) {
        if(theirName && theirPhone && email && firstname && lastname && phone && terms) {
          nextDisabled = false
        } else {
          nextDisabled = true
        }
      } else {
        if (email && firstname && lastname && phone && terms ) {
          nextDisabled = false
        } else {
          nextDisabled = true
        }
      }
      if(value) {
        return true
      } else {
        return nextDisabled
      }
    }
  }

  numberWithCommas(x) {
    if(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return ""
    }
  }

  onClick(e) {
    this.setState({ terms: !this.state.terms }, () => {
      this.nextDisabled()
    })
  }

  pointOfContact(e) {
    this.setState({ pointOfContact: !this.state.pointOfContact },()=>{
      if(this.state.pointOfContact) {
        this.setState({ theirName: '', theirPhone: '' })
      }
    })
  }

  handleCouponChange (e){
    this.setState({ [e.target.name]: e.target.value },()=>{
      if (this.state.couponCode === ''){
        this.setState({ discount : 0 })
      }
    })
  }

  applyCouponCode () {
    if (this.state.couponCode) {
      this.setState({ couponCodeError: "", couponCodeDisabled: true })
      fetch(`${HOSTNAME}/api/orders/applyCouponCode`,{
        method:'POST',
        headers:{
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ couponCode: this.state.couponCode })
      }).then((res) =>  {
        return res.json()
      }).then((data) => {
        if(data.error) {
          message.error(data.error.message)
          this.setState({ couponCodeDisabled: false },()=>{
            this.forceUpdate()
          })
        } else{
          this.setState({discount:data.data.percent_off, couponCodeDisabled: false},()=>{
            this.forceUpdate()
          })
        }
      }).catch((err) => {
        console.log(err, 'errrr')
        this.setState({ couponCodeDisabled: false })
        throw err
      });
    } else{
      this.setState({ couponCodeError: "Please enter coupon code" })
    }
  }

  validate() {
    let errObj = {}
    const { email, paymentGatewayUsed,  phone, theirPhone, theirName, cardnumber, nameOncard, securityCode, expDate } = this.state
    if (email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false) { // eslint-disable-line
        errObj.email = "Please enter a valid email address"
      }
    }
    if(email === "") {
      errObj.email = "Email is required"
    }

    const phonelength = phone.replace(/[^0-9]/g,"").length
    if(phonelength !== 10) {
      errObj.phone = "Please enter exactly 10 digit number"
    }
    if(phone === "") {
      errObj.phone = "Phone number is required"
    }
    if(!this.state.pointOfContact) {
      if(theirName === "") {
        errObj.theirName = "Name is required"
      }
      if(theirPhone) {
        const theirPhonelength = theirPhone.replace(/[^0-9]/g,"").length
        if(theirPhonelength !== 10) {
          errObj.theirPhone = "Please enter exactly 10 digit number"
        }
      }
      if(theirPhone === "") {
        errObj.theirPhone = "Phone number is required"
      }
    }
    if (paymentGatewayUsed === 'stripe') {
      if(cardnumber === "") {
        errObj.cardnumber = "Card number is required"
      }
      if(nameOncard === "") {
        errObj.nameOncard = "Card name is required"
      }
      if(securityCode === "") {
        errObj.securityCode = "Security code is required"
      }
      if(expDate === "") {
        errObj.expDate = "Expiration date is required"
      }
    }
    this.setState({ err: errObj },()=>{
      this.forceUpdate()
    })
  }

  submit= async ()=> {
    await this.validate()
    const { err } = this.state
    if(Object.keys(err).length === 0) {
      this.setState({ loadervisible: true })
      const { email, firstname, lastname, phone, expDate, theirPhone, theirName, cardnumber, nameOncard, securityCode, couponCode, pointOfContact } = this.state
      const data = {
        email, firstname, lastname, theirName, cardnumber, nameOncard, securityCode, expDate,
        phone: phone.replace(/[^0-9]/g,""),
        theirPhone: theirPhone.replace(/[^0-9]/g,""),
        couponCode, pointOfContact,
        name: firstname + " " + lastname
      }
      this.nextDisabled(true)
      try {
        const stripe = await load(stripePublishKey);
          const stripeObj = {
            number: cardnumber,
            exp_month: expDate.split("/")[0],
            exp_year: `${expDate.split("/")[1]}`,
            cvc: securityCode
          }
          const { id, ...stripeData } = await stripe.card.createToken(stripeObj)
          data.id = id
          data.stripeData = stripeData
          await this.bookOrder(data)

      } catch ({ message, param }) {
        this.setState({ loadervisible: false });
        message.error(message)
        //alert(message)
      }
    }
  }

  onBlur() {
    const mailCredential = {
      email: this.state.email,
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      phone: this.state.phone

    }
    fetch(`${HOSTNAME}/api/quotes/updateOnHubSpot`,{
      method:'POST',
      headers:{accept: 'application/json', 'content-type': 'application/json'},
      body: JSON.stringify(mailCredential)
    }).then((res) =>  {
      return res.json()
    }).then((data) => {
      //console.log(data)
    })
  }

  bookOrder(PlaceOrder) {

    let firstStepData = _.get(this.state, 'firstStepData', '')
    let addedmaterials = _.get(this.state,'addedmaterials', [])
    const hevaydebris = ['Roofing', 'Concrete', 'Brick', 'Dirt/Sand', 'Sheetrock', 'Asphalt', 'Stone', 'Mixed Masonry']
    let finalData = {
      containersize: _.get(firstStepData, 'containerSize', ''),
      new_address: _.get(firstStepData, 'streetaddress', ''),
      address: _.get(firstStepData, 'address', ''),
      city: _.get(firstStepData,'city', ''),
      zipcode: _.get(firstStepData,'zipcode', ''),
      state: _.get(firstStepData,'state', ''),
      borough: _.get(firstStepData,'borough', ''),
      neighborhood: _.get(firstStepData,'neighborhood', ''),
      randomString: _.get(firstStepData,'randomString', ''),
      gclid: _.get(firstStepData,'gclid', ''),
      location: {
        lat: _.get(firstStepData,'lat', 0),
        lng: _.get(firstStepData,'lng', 0),
      },
      containerlocation: _.get(firstStepData,'placement', '') === "On Street" ?_.get(firstStepData,'containerlocation', '') : "",
      placement: _.get(firstStepData,'placement', ''),
      otherPlacement: _.get(firstStepData,'otherPlacement', ''),
      typeofdebris: addedmaterials,
      requestedTime: _.get(firstStepData, 'requestedTime', ''),
      deliveryday: _.get(firstStepData, 'deliveryday', ''),
      specialinstruction: `Requested time: ${_.get(firstStepData, 'requestedTime', '')}  ${_.get(this.state, 'specialInstructions', '')}`,
      contactname: _.get(PlaceOrder,'name', ''),
      email: _.get(PlaceOrder,'email', '').toLowerCase(),
      firstname: _.get(PlaceOrder, 'firstname', ''),
      lastname: _.get(PlaceOrder, 'lastname', ''),
      contactnumber: formatPhoneNumber(_.get(PlaceOrder,'phone', '')),
      orderedby: this.state.pointOfContact === true? _.get(PlaceOrder,'name', '') :_.get(PlaceOrder,'theirName', ''),
      orderedbycontact: this.state.pointOfContact === true? formatPhoneNumber(_.get(PlaceOrder,'phone', '')) : formatPhoneNumber(_.get(PlaceOrder,'theirPhone', '')),
      paymenttype: "Credit Card",
      pricingtype: hevaydebris.indexOf(addedmaterials) !== -1 ? "Fixed" : "Matrix",
      permit: this.state.permit,
      // cardnumber: _.get(PlaceOrder,'cardnumber', ''),
      // nameOncard: _.get(PlaceOrder,'nameOncard', ''),
      // securityCode: _.get(PlaceOrder,'securityCode', ''),
      // expDate: _.get(PlaceOrder,'expDate', ''),
      couponCode: _.get(PlaceOrder,'couponCode', ''),
      couponAmount: (parseInt(this.state.totalPrice)*parseInt(this.state.discount))/100,
      discountPercentage: this.state.discount,
      id: _.get(PlaceOrder,'id', ''),
      stripeData: _.get(PlaceOrder,'stripeData', ''),
      expDate: _.get(PlaceOrder,'expDate', '')
    }
    const timezone = {}
    const date = new Date().getTimezoneOffset()
    timezone.clientoffset = date
    timezone.clienttimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (this.state.discount > 0) {
      finalData.amount= this.state.totalPrice-(parseFloat(this.state.totalPrice)*parseFloat(this.state.discount))/100
      finalData.consumercost = this.state.totalPrice-(parseFloat(this.state.totalPrice)*parseFloat(this.state.discount))/100
    } else {
      finalData.amount= this.state.totalPrice
      finalData.consumercost= this.state.totalPrice
    }

    if (_.get(firstStepData, 'containerSize', '') === "Live Load") {
      finalData.live_load_yard = _.get(firstStepData, 'live_load_yard', '')
    }
    finalData.deliverydate = moment(_.get(firstStepData, 'requestedDate', '')).format('YYYY-MM-DDTHH:mm:ss')
    finalData.createdat =new Date()
    finalData.timezone = timezone
    this.setState({ email: '', firstname: '', lastname: '', phone: '' })
    fetch(`${HOSTNAME}/api/orders/placeOrder`,{
      method:'POST',
      headers:{
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(finalData)
    }).then((res) =>  {
      return res.json()
    }).then((data) => {
      if(data.error) {
        //message.error(data.error.message)
        if(data && data.error && data.error.statusCode && data.error.statusCode === 400 ){
          this.setState({ openModal: true, loadervisible: false })
        } else {
          this.setState({ loadervisible: false })
          message.error(_.get(data, 'error.message', "Unable to place your orde").replace('.',''))
        }
        // if(data.error.message === "Email already exists") {
        //   this.setState({openModal: true})
        // }
      } else {
        this.setState({ loadervisible: false })
        navigate(`/confirmation/${data.data.orderid}`,
          {
            state: {
              data: data.data,
            },
          }
        );
      }
    }).catch((err) => {
      this.setState({ loadervisible: false })
      message.error("Unable to place your order")
      throw err
    });
  }

  closeModal() {
    this.setState({ openModal: false })
  }
  mobileViewToggle () {
    this.setState({ paymentSectionForMobile: !this.state.paymentSectionForMobile })
  }

  cardNonceResponseReceived = async(errors, nonce, cardData, buyerVerificationToken) => {
    if (errors) {
      this.setState({ errorMessages: errors.map((error) => error.message) });
      return;
    }
    console.log({errors, nonce})
    this.setState({ errorMessages: [], loadervisible: true })
    const { email, firstname, lastname, phone, expDate, theirPhone, theirName, cardnumber, nameOncard, securityCode, couponCode, pointOfContact } = this.state
    const data = {
      email, firstname, lastname, theirName, cardnumber, nameOncard, securityCode, expDate,
      phone: phone.replace(/[^0-9]/g,""),
      theirPhone: theirPhone.replace(/[^0-9]/g,""),
      couponCode, pointOfContact,
      name: firstname + " " + lastname
    }
    data.id = nonce
    data.stripeData = {}
		await this.bookOrder(data)
  };

  createVerificationDetails() {
    return {
      amount: "100.00",
      currencyCode: "USD",
      intent: "CHARGE",
      billingContact: {
        familyName: "Smith",
        givenName: "John",
        email: "jsmith@example.com",
        country: "GB",
        city: "London",
        addressLines: ["1235 Emperor's Gate"],
        postalCode: "SW7 4JA",
        phone: "020 7946 0532",
      },
    };
  }

  render() {
    const { err, squareApplicationId, squareLocationId } = this.state
    return (
      <div>
      { this.state.loadervisible ?
        <div className="fullpage-loader">
          <span className="loaderimg">
              <div className="spinner-border text-warning" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </span>
        </div> :
      "" }
      <header className="payment__header">
        <Link to="/">
          <img src={headerLogo} alt="" />
        </Link>
      </header>

        <section className="payment__section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="right__section tabright order-sm-12">
                  <h1>Order Overview</h1>

                  <div className="total__bg total__bgmobile">
                    <span className="total__label">{_.get(this.state, 'firstStepData.containerSize', '') === "Live Load" ? "Todays Total " : "Todays Total "}</span>
                    { this.state.discount > 0 ?
                      <span className="total__amount">${this.numberWithCommas((this.state.totalPrice-(parseInt(this.state.totalPrice)*parseInt(this.state.discount))/100).toFixed(2))}
                        <div className="tooltipsdiv ml-2">
                    <img src={infoIcon} alt="" />
                        <p className="tooltip-para">The price of this container includes {_.get(this.state, 'firstStepData.tons', '')} tons of material. If you go over the included amount you will be charged for the additional tonnage after the container is picked up. If you're unsure or have any questions, reach out to one of our experts who will gladly help!</p>
                    </div>
                      </span>
                      :
                      <span className="total__amount">${this.numberWithCommas(this.state.totalPrice)}
                        <div className="tooltipsdiv ml-2">
                          <img src={infoIcon} alt="" />
                          <p className="tooltip-para">The price of this container includes {_.get(this.state, 'firstStepData.tons', '')} tons of material. If you go over the included amount you will be charged for the additional tonnage after the container is picked up. If you're unsure or have any questions, reach out to one of our experts who will gladly help!</p>
                        </div>
                      </span>
                    }
                    <div className="btm-details">
                      <span className="datesec">{_.get(this.state, 'firstStepData.deliveryday', '')} - {moment(_.get(this.state, 'firstStepData.requestedDate', '')).format('MM/DD/YYYY')}</span>
                      <span onClick={this.mobileViewToggle.bind(this)} className="viewsec">View Order Overview</span>
                    </div>
                  </div>



                  { this.state.paymentSectionForMobile ?
                    <ul className="paymentdetails-tablet">
                      {_.get(this.state, 'firstStepData.tons', '') && _.get(this.state, 'firstStepData.containerSize', '') !== "Live Load" && (
                        <li>
                          <h5>Included Tonnage<span>{_.intersection(this.state.heavyMaterials, _.get(this.state,'addedmaterials', [])).length === 0 ? `${_.get(this.state, 'firstStepData.tons', '')} Tons` : "N/A"}</span></h5>
                        </li>
                      )}
                      {_.get(this.state, 'firstStepData.containerSize', '') === "Live Load" ?
                        <li>
                          <h5>Estimated Yardage<span>{_.get(this.state, 'firstStepData.live_load_yard', '')}</span></h5>
                        </li>
                        : ""
                      }
                      {_.get(this.state, 'firstStepData.containerSize', '') === "Live Load" ?
                        <li>
                          <h5>Price per yard<span>${_.get(this.state, 'priceperyard', '')}</span></h5>
                        </li>
                        : ""
                      }
                      {_.get(this.state, 'firstStepData.containerSize', '') === "Live Load" ?
                        <li>
                          <h5>Estimated Yardage Price<span>${this.numberWithCommas(this.state.basePrice)}</span></h5>
                        </li>
                        : ""
                      }
                      {_.get(this.state, 'firstStepData.containerSize', '') !== "Live Load" ?
                        <li>
                          <h5>Container Size<span>{_.get(this.state, 'firstStepData.containerSize', '')}</span></h5>
                        </li>
                        : ""
                      }
                      {_.get(this.state, 'firstStepData.containerSize', '') !== "Live Load" ?
                        <li>
                          <h5>Materials	<span>{_.get(this.state, 'addedmaterials', []).join(', ')}</span></h5>
                        </li>
                        : ""
                      }
                      <li>
                        <h5>Delivery Date<span>{_.get(this.state, 'firstStepData.deliveryday', '')} - {moment(_.get(this.state, 'firstStepData.requestedDate', '')).format('MM/DD/YYYY')}</span></h5>
                      </li>
                      <li>
                        <h5>Address<span>{_.get(this.state, 'firstStepData.address', '')}</span></h5>
                      </li>
                      {_.get(this.state, 'firstStepData.containerSize', '') === "Live Load" ?
                        <li>
                          <h5>Trucking Rate
                            <div className="tooltipsdiv">
                              <img src={infoIcon} alt="" />
                              <p className="tooltip-para">This is a fixed rate that will be charged upon the approval of this order.</p>
                            </div>
                          <span>${_.get(this.state, 'truckingrate', '')}</span></h5>
                        </li>
                        : ""
                      }
                      {_.get(this.state, 'firstStepData.containerSize', '') !== "Live Load" ?
                        <li>
                          <h5>Base<span>${this.numberWithCommas(this.state.basePrice)}</span></h5>
                        </li>
                        : ""
                      }
                      { this.state.deliveryFee > 0 &&  _.get(this.state, 'firstStepData.containerSize', '') === '1/2 Yard' ?
                        <li>
                          <h5>Delivery Fee<span>${this.state.deliveryFee}</span></h5>
                        </li>
                        : ""
                      }
                      { this.state.permit !== false &&  _.get(this.state, 'firstStepData.containerSize', '') !== "Live Load"  ?
                        <li>
                          <h5>Permit
                            <div className="tooltipsdiv">
                              <img src={infoIcon} alt="" />
                              <p className="tooltip-para">When placing a dumpster on the street, New York City Department of Transportation requires a permit be issued. No worries, we will pull this permit and provide it to you. Please note, the permit is valid for 5 days. If you need your dumpster longer than 5 days, we will continue to renew until it's ready to go.</p>
                            </div>
                            <span>${54.44}</span></h5>
                        </li>
                        : ""
                      }
                      <li>
                        <h5>Estimated Taxes<span>${this.state.estimated}</span></h5>
                      </li>
                      { this.state.discount > 0 ?
                        <li>
                          <h5>Discount<span>${((parseFloat(this.state.totalPrice)*parseFloat(this.state.discount))/100).toFixed(2)}</span></h5>
                        </li>
                        : ""
                      }
                    </ul> : ""
                  }


                  <ul className="paymentdetails-desktop">
                    {_.get(this.state, 'firstStepData.tons', '') && _.get(this.state, 'firstStepData.containerSize', '') !== "Live Load" && (
                      <li>
                        <h5>Included Tonnage<span>{_.intersection(this.state.heavyMaterials, _.get(this.state,'addedmaterials', [])).length === 0 ? `${_.get(this.state, 'firstStepData.tons', '')} Tons` : "N/A"}</span></h5>
                      </li>
                    )}
                    {_.get(this.state, 'firstStepData.containerSize', '') === "Live Load" ?
                      <li>
                        <h5>Estimated Yardage<span>{_.get(this.state, 'firstStepData.live_load_yard', '')}</span></h5>
                      </li>
                      : ""
                    }
                    {_.get(this.state, 'firstStepData.containerSize', '') === "Live Load" ?
                      <li>
                        <h5>Price per yard<span>${_.get(this.state, 'priceperyard', '')}</span></h5>
                      </li>
                      : ""
                    }
                    {_.get(this.state, 'firstStepData.containerSize', '') === "Live Load" ?
                      <li>
                        <h5>Estimated Yardage Price<span>${this.numberWithCommas(this.state.basePrice)}</span></h5>
                      </li>
                      : ""
                    }
                    {_.get(this.state, 'firstStepData.containerSize', '') !== "Live Load" ?
                      <li>
                        <h5>Container Size<span>{_.get(this.state, 'firstStepData.containerSize', '')}</span></h5>
                      </li>
                      : ""
                    }
                    {_.get(this.state, 'firstStepData.containerSize', '') !== "Live Load" ?
                      <li>
                        <h5>Materials	<span>{_.get(this.state, 'addedmaterials', []).join(', ')}</span></h5>
                      </li>
                      : ""
                    }
                    <li>
                      <h5>Delivery Date<span>{_.get(this.state, 'firstStepData.deliveryday', '')} - {moment(_.get(this.state, 'firstStepData.requestedDate', '')).format('MM/DD/YYYY')}</span></h5>
                    </li>
                    <li>
                      <h5>Address<span>{_.get(this.state, 'firstStepData.address', '')}</span></h5>
                    </li>
                    {_.get(this.state, 'firstStepData.containerSize', '') === "Live Load" ?
                      <li>
                        <h5>Trucking Rate
                          <div className="tooltipsdiv">
                            <img src={infoIcon} alt="" />
                            <p className="tooltip-para">This is a fixed rate that will be charged upon the approval of this order.</p>
                          </div>
                        <span>${_.get(this.state, 'truckingrate', '')}</span></h5>
                      </li>
                      : ""
                    }
                    {_.get(this.state, 'firstStepData.containerSize', '') !== "Live Load" ?
                      <li>
                        <h5>Base<span>${this.numberWithCommas(this.state.basePrice)}</span></h5>
                      </li>
                      : ""
                    }
                    { this.state.deliveryFee > 0 &&  _.get(this.state, 'firstStepData.containerSize', '') === '1/2 Yard' ?
                      <li>
                        <h5>Delivery Fee<span>${this.state.deliveryFee}</span></h5>
                      </li>
                      : ""
                    }
                    { this.state.permit !== false &&  _.get(this.state, 'firstStepData.containerSize', '') !== "Live Load"  ?
                      <li>
                        <h5>Permit
                          <div className="tooltipsdiv">
                            <img src={infoIcon} alt="" />
                            <p className="tooltip-para">When placing a dumpster on the street, New York City Department of Transportation requires a permit be issued. No worries, we will pull this permit and provide it to you. Please note, the permit is valid for 5 days. If you need your dumpster longer than 5 days, we will continue to renew until it's ready to go.</p>
                          </div>
                          <span>${54.44}</span></h5>
                      </li>
                      : ""
                    }
                    <li>
                      <h5>Estimated Taxes<span>${this.state.estimated}</span></h5>
                    </li>
                    { this.state.discount > 0 ?
                      <li>
                        <h5>Discount<span>${((parseFloat(this.state.totalPrice)*parseFloat(this.state.discount))/100).toFixed(2)}</span></h5>
                      </li>
                      : ""
                    }
                  </ul>
                  <div className="total__bg">
                    <span className="total__label">{_.get(this.state, 'firstStepData.containerSize', '') === "Live Load" ? "Estimated Total Price" : "Total Price"}
                    <div className="tooltipsdiv">
                    <img src={infoIcon} alt="" />
                        <p className="tooltip-para">The price of this container includes {_.get(this.state, 'firstStepData.tons', '')} tons of material. If you go over the included amount you will be charged for the additional tonnage after the container is picked up. If you're unsure or have any questions, reach out to one of our experts who will gladly help!</p>
                    </div>
                    </span>
                    { this.state.discount > 0 ?
                      <span className="total__amount">${this.numberWithCommas((this.state.totalPrice-(parseInt(this.state.totalPrice)*parseInt(this.state.discount))/100).toFixed(2))}</span>
                      :
                      <span className="total__amount">${this.numberWithCommas(this.state.totalPrice)}</span>
                    }
                  </div>
                </div>
                <div className="left__section order-sm-12">
                  <div className="left__section--inner">
                  <h1>Place Order</h1>
                  <div className="list__section">
                    <h4>What do you plan on putting inside of your dumpster?</h4>
                    <p>Select all the materials that apply</p>
                    <ul>
                      <li onClick={this.addMaterial.bind(this, 'Roofing')}>
                        { this.state.addedmaterials.indexOf('Roofing') !== -1 ?
                          <span className="active"><img src={roofingWhiteIcon} alt="" /></span>
                          :
                          <span><img src={roofingIcon} alt="" /></span>
                        }
                        <h5>Roofing</h5>
                      </li>
                      <li onClick={this.addMaterial.bind(this, 'Cardboard/Paper')}>
                        { this.state.addedmaterials.indexOf('Cardboard/Paper') !== -1 ?
                          <span className="active"><img src={cardboardWhiteIcon} alt="" /></span>
                          :
                          <span><img src={cardboardIcon} alt="" /></span>
                        }
                        <h5>Cardboard/Paper</h5>
                      </li>
                      <li onClick={this.addMaterial.bind(this, 'Furniture')}>
                        { this.state.addedmaterials.indexOf('Furniture') !== -1 ?
                        <span className="active"><img src={furnitureWhiteIcon} alt="" /></span>
                        :
                        <span><img src={furnitureIcon} alt="" /></span>
                        }
                        <h5>Furniture</h5>
                      </li>
                      <li onClick={this.addMaterial.bind(this, 'Dirt/Sand')}>
                        { this.state.addedmaterials.indexOf('Dirt/Sand') !== -1 ?
                        <span className="active"><img src={dirtsandWhiteIcon} alt="" /></span>
                        :
                        <span><img src={dirtsandIcon} alt="" /></span>
                        }
                        <h5>Dirt/Sand</h5>
                      </li>
                      <li onClick={this.addMaterial.bind(this, 'Asphalt')}>
                        { this.state.addedmaterials.indexOf('Asphalt') !== -1 ?
                        <span className="active"><img src={asphaltWhiteIcon} alt="" /></span> :
                        <span><img src={asphaltIcon} alt="" /></span> }
                        <h5>Asphalt</h5>
                      </li>


                      <li onClick={this.addMaterial.bind(this, 'Appliances')}>
                        { this.state.addedmaterials.indexOf('Appliances') !== -1 ?
                        <span className="active"><img src={appliancesWhiteIcon} alt="" /></span> :
                        <span><img src={appliancesIcon} alt="" /></span> }
                        <h5>Appliances</h5>
                      </li>
                      <li onClick={this.addMaterial.bind(this, 'Concrete')}>
                        { this.state.addedmaterials.indexOf('Concrete') !== -1 ?
                        <span className="active"><img src={concreteWhiteIcon} alt="" /></span> :
                        <span><img src={concreteIcon} alt="" /></span> }
                        <h5>Concrete</h5>
                      </li>
                      <li onClick={this.addMaterial.bind(this, 'Sheetrock')}>
                        { this.state.addedmaterials.indexOf('Sheetrock') !== -1 ?
                        <span className="active"><img src={sheetrockWhiteIcon} alt="" /></span> :
                        <span><img src={sheetrockIcon} alt="" /></span> }
                        <h5>Sheetrock</h5>
                      </li>
                      <li onClick={this.addMaterial.bind(this, 'Brick')}>
                        { this.state.addedmaterials.indexOf('Brick') !== -1 ?
                        <span className="active"><img src={brickWhiteIcon} alt="" /></span> :
                        <span><img src={brickIcon} alt="" /></span> }
                        <h5>Brick</h5>
                      </li>
                      <li onClick={this.addMaterial.bind(this, 'Tile')}>
                        { this.state.addedmaterials.indexOf('Tile') !== -1 ?
                        <span className="active"><img src={tileWhiteIcon} alt="" /></span> :
                        <span><img src={tileIcon} alt="" /></span> }
                        <h5>Tile</h5>
                      </li>


                      <li onClick={this.addMaterial.bind(this, 'Stone')}>
                        { this.state.addedmaterials.indexOf('Stone') !== -1 ?
                        <span className="active"><img src={stoneWhiteIcon} alt="" /></span>:
                        <span><img src={stoneIcon} alt="" /></span>}
                        <h5>Stone</h5>
                      </li>
                      <li onClick={this.addMaterial.bind(this, 'Glass')}>
                        { this.state.addedmaterials.indexOf('Glass') !== -1 ?
                        <span className="active"><img src={glassWhiteIcon} alt="" /></span>:
                        <span><img src={glassIcon} alt="" /></span>}
                        <h5>Glass</h5>
                      </li>
                      <li onClick={this.addMaterial.bind(this, 'Metal')}>
                        { this.state.addedmaterials.indexOf('Metal') !== -1 ?
                        <span className="active"><img src={metalWhiteIcon} alt="" /></span>:
                        <span><img src={metalIcon} alt="" /></span> }
                        <h5>Metal</h5>
                      </li>
                      <li onClick={this.addMaterial.bind(this, 'Wood')}>
                        { this.state.addedmaterials.indexOf('Wood') !== -1 ?
                        <span className="active"><img src={woodWhiteIcon} alt="" /></span> :
                        <span><img src={woodIcon} alt="" /></span> }
                        <h5>Wood</h5>
                      </li>
                      <li onClick={this.addMaterial.bind(this, 'Plastic')}>
                        { this.state.addedmaterials.indexOf('Plastic') !== -1 ?
                        <span className="active"><img src={plasticWhiteIcon} alt="" /></span>:
                        <span><img src={plasticIcon} alt="" /></span> }
                        <h5>Plastic</h5>
                      </li>


                       <li onClick={this.addMaterial.bind(this, 'Putrescible Debris')}>
                         { this.state.addedmaterials.indexOf('Putrescible Debris') !== -1 ?
                          <span className="active"><img src={putrescibleWhiteIcon} alt="" /></span>:
                          <span><img src={putrescibleIcon} alt="" /></span> }
                        <h5>Putrescible Debris</h5>
                      </li>
                      <li onClick={this.addMaterial.bind(this, 'Greens')}>
                        { this.state.addedmaterials.indexOf('Greens') !== -1 ?
                        <span className="active"><img src={greensWhiteIcon} alt="" /></span> :
                        <span><img src={greensIcon} alt="" /></span> }
                        <h5>Greens</h5>
                      </li>
                      <li onClick={this.addMaterial.bind(this, 'Mixed C&D')}>
                        { this.state.addedmaterials.indexOf('Mixed C&D') !== -1 ?
                        <span className="active"><img src={mixedWhiteIcon} alt="" /></span> :
                        <span><img src={mixedIcon} alt="" /></span> }
                        <h5>Mixed C&D</h5>
                      </li>
                      <li onClick={this.addMaterial.bind(this, 'Mixed Masonry')}>
                        { this.state.addedmaterials.indexOf('Mixed Masonry') !== -1 ?
                        <span className="active"><img src={masonryWhiteIcon} alt="" /></span> :
                        <span><img src={masonryIcon} alt="" /></span> }
                        <h5>Mixed Masonry</h5>
                      </li>

                    </ul>
                    <div className="clearfix"></div>

                    <div className="form-group">
                      <label className="services__label">Special Instructions (optional)</label>
                      <textarea
                       //  placeholder="Are there any additional instructions regarding this drop off that we should know about?"
                        placeholder={_.get(this.state, 'firstStepData.containerSize', '') !== "Live Load" ? "Are there any additional instructions regarding this drop off that we should know about?" :"Are there any additional instructions regarding this pickup that we should know about?"}
                        className="textarea__global"
                        name="specialInstructions"
                        value={this.state.specialInstructions}
                        onChange={this.handleChange.bind(this)}
                      >
                      </textarea>
                    </div>

                  </div>


                  <div className="info__section">
                    <h4>Your Information</h4>
                    <ul>
                      <li>
                        <div className="form-group">
                          <label htmlFor="firstname" className="services__label">Order Contact First Name</label>
                          <input
                            type="text"
                            placeholder="What’s your first name?"
                            className="services__input"
                            name="firstname"
                            onChange={this.handleChange.bind(this)}
                            onBlur={this.onBlur.bind(this)}
                            value={this.state.firstname}
                          />
                        </div>
                      </li>
                      <li>
                        <div className="form-group">
                          <label htmlFor="lastname" className="services__label">Order Contact Last Name</label>
                          <input
                            type="text"
                            placeholder="What’s your last name?"
                            className="services__input"
                            name="lastname"
                            onBlur={this.onBlur.bind(this)}
                            onChange={this.handleChange.bind(this)}
                            value={this.state.lastname}
                          />
                        </div>
                      </li>
                      <li>
                        <div className="form-group">
                          <label htmlFor="email" className="services__label">Order Contact Email</label>
                          <input
                            type="text"
                            placeholder="What’s your email address?"
                            className="services__input"
                            name="email"
                            onChange={this.handleChange.bind(this)}
                            value={this.state.email}
                          />
                          <span className="errors">{err && err.email ? err.email : ""}</span>
                        </div>
                      </li>
                      <li>
                        <div className="form-group">
                          <label htmlFor="phone" className="services__label">Order Contact Phone Number</label>
                          {/* <input type="text" placeholder="What’s your phone number?" className="services__input" /> */}
                          <InputMask
                            type="text"
                            guide={false}
                            keepCharPositions={false}
                            placeholder="What’s your phone number?"
                            name="phone"
                            value={this.state.phone}
                            onBlur={(event) =>  this.onBlur(event)}
                            mask={phoneNumberMask}
                            onChange={(event) => this.handleChange(event)}
                            className="services__input" />
                            <span className="errors">{err && err.phone ? err.phone : ""}</span>
                        </div>
                      </li>
                    </ul>
                  </div>


                  <div className="info__section">
                    <h4>Point of Contact</h4>
                    <p>Enter the information for the person that will be on site</p>

                      <div onClick={this.pointOfContact.bind(this)} className="checkboxlabel">
                        <label htmlFor="pointOfContact" className="custom-checkbox custom-checkbox-line">
                          <input
                            className="custom-checkbox--input"
                            type="checkbox"
                            readOnly
                            checked={this.state.pointOfContact}
                          />
                            I’m the point of contact
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    {!this.state.pointOfContact ?
                      <ul>
                        <li>
                          <div className="form-group">
                            <label htmlFor="theirName" className="services__label">Point of Contact Name</label>
                            <input
                              type="text"
                              placeholder="What’s their first and last name?"
                              name="theirName"
                              value={this.state.theirName}
                              onChange={(event) => this.handleChange(event)}
                              className="services__input" />
                          </div>
                        </li>
                        <li>
                          <div className="form-group">
                            <label htmlFor="theirPhone" className="services__label">Point of Contact Phone</label>
                            {/* <input type="text" placeholder="What’s their phone number?" className="services__input" /> */}
                            <InputMask
                              type="text"
                              guide={false}
                              keepCharPositions={false}
                              placeholder="What’s their phone number?"
                              className="services__input"
                              name="theirPhone"
                              value={this.state.theirPhone}
                              mask={phoneNumberMask}
                              onChange={(event) => this.handleChange(event)}
                            />
                            <span className="errors">{err && err.theirPhone ? err.theirPhone : ""}</span>
                          </div>
                        </li>
                      </ul>: ''}
                  </div>
                  {this.state.paymentGatewayUsed === 'stripe' ?
                    <div className="info__section border__none">
                      <h4>Payment</h4>
                      <p>We use Stripe to securely store and process your payment info. <br/>
                      All transacation are secure and encrypted
                      </p>
                        <div htmlFor="cardnumber" className="form-group posi__relative">
                        <label className="services__label">Card Number</label>
                        {/* <input type="text" placeholder="Enter your credit card" className="services__input" /> */}
                        <InputMask
                          guide={false}
                          type="text"
                          keepCharPositions={false}
                          mask={cardNumberMask}
                          name='cardnumber'
                          placeholder="Enter your credit card"
                          value={this.state.cardnumber}
                          className="services__input"
                          onChange={this.handleChange.bind(this)} />
                        <span className="info__mark"><img src={lockIcon} alt="" /></span>
                        <span className="errors">{err && err.cardnumber ? err.cardnumber : ""}</span>
                      </div>
                      <div className="form-group">
                        <label htmlFor="nameOncard" className="services__label">Name on Card</label>
                        <input
                          type="text"
                          placeholder="Enter the name on the card"
                          name='nameOncard'
                          value={this.state.nameOncard}
                          onChange={this.handleChange.bind(this)}
                          className="services__input" />
                      </div>
                      <ul>
                        <li>
                          <div className="form-group">
                            <label htmlFor="expDate" className="services__label">Expiration date (MM/YY)</label>
                              <InputMask
                                guide={false}
                                type="text"
                                keepCharPositions={false}
                                mask={expDateMask}
                                placeholder="MM/YY"
                                className="services__input"
                                name="expDate"
                                value={this.state.expDate}
                                onChange={this.handleChange.bind(this)}
                              />
                              <span className="errors">{err && err.expDate ? err.expDate : ""}</span>
                          </div>
                        </li>
                        <li>
                          <div className="form-group posi__relative">
                            <label htmlFor="theirName" className="services__label">Security code</label>
                            <InputMask
                              type="text"
                              keepCharPositions={false}
                              mask={cvvMask}
                              guide={false}
                              placeholder="Enter your security code "
                              className="services__input"
                              name='securityCode'
                              value={this.state.securityCode}
                              onChange={this.handleChange.bind(this)}
                            />
                            <span className="errors">{err && err.securityCode ? err.securityCode : ""}</span>
                            <span className="info__mark">
                                <div className="tooltipsdiv">
                                  <img src={infoIcon} alt="" />
                                  <p className="tooltip-para">The price of this container includes ${_.get(this.props, 'containerData.tons', '')} tons of material. If you go over the included amount you will be charged for the additional tonnage after the container is picked up. If you're unsure or have any questions, reach out to one of our experts who will gladly help!</p>
                                </div>
                            </span>
                          </div>
                        </li>
                      </ul>
                      <div className="form-group">
                        <label htmlFor="theirName" className="services__label">Coupon Code</label>
                        <div className="couponcode">
                          <input
                            type="text"
                            placeholder="Enter coupon code "
                            name="couponCode"
                            value={this.state.couponCode}
                            onChange={this.handleCouponChange.bind(this)}
                            className="services__input" />
                          <button disabled={this.state.couponCodeDisabled} onClick={this.applyCouponCode.bind(this)} className="btn btn__apply">Apply</button>
                        </div>
                        <span className="errors">{this.state.couponCodeError ? this.state.couponCodeError : ""}</span>
                      </div>

                      <div className="checkboxlabel">
                          <label htmlFor="term" className="custom-checkbox custom-checkbox-line">
                            <input id="htm2" type="checkbox" readOnly onClick={this.onClick.bind(this)} checked={this.state.terms} className="custom-checkbox--input" />
                          By clicking Book, you agree to our <span><a href={'/terms'}  target="_blank">Terms and Conditions</a></span>
                            <span onClick={this.onClick.bind(this)} className="checkmark"></span>
                          </label>
                      </div>
                      <button disabled={this.nextDisabled()} onClick={this.submit.bind(this)} className="btn btn-complete">Complete Booking</button>
                    </div>
                  :
                  this.state.paymentGatewayUsed === 'square' ?
                  <SquarePaymentForm
                    sandbox={process.env.REACT_APP_SQUARE_PAYMENTMODE === 'SANDBOX'}
                    applicationId={base64.decode(squareApplicationId)}
                    locationId={base64.decode(squareLocationId)}
                    cardNonceResponseReceived={this.cardNonceResponseReceived}
                    createVerificationDetails={this.createVerificationDetails}
                    >
                      <div className="info__section border__none">
                      <h4>Payment</h4>
                      <p>We use Stripe to securely store and process your payment info. <br/>
                      All transacation are secure and encrypted
                      </p>
                        <div htmlFor="cardnumber" className="form-group posi__relative">
                        {/* <label className="services__label">Card Number</label> */}
                        {/* <input type="text" placeholder="Enter your credit card" className="services__input" /> */}
                        <CreditCardNumberInput />
                        <span className="info__mark"><img src={lockIcon} alt="" /></span>
                        <span className="errors">{err && err.cardnumber ? err.cardnumber : ""}</span>
                      </div>
                      <ul>
                        <li>
                          <div className="form-group">
                            {/* <label htmlFor="expDate" className="services__label">Expiration date (MM/YY)</label> */}
                            <CreditCardExpirationDateInput />
                              <span className="errors">{err && err.expDate ? err.expDate : ""}</span>
                          </div>
                        </li>
                        <li>
                          <div className="form-group">
                            {/* <label htmlFor="expDate" className="services__label">Expiration date (MM/YY)</label> */}
                            <CreditCardPostalCodeInput />
                              <span className="errors">{err && err.expDate ? err.expDate : ""}</span>
                          </div>
                        </li>
                        <li>
                          <div className="form-group posi__relative">
                            {/* <label htmlFor="theirName" className="services__label">Security code</label> */}
                            <CreditCardCVVInput />
                            <span className="errors">{err && err.securityCode ? err.securityCode : ""}</span>
                            <span className="info__mark">
                                <div className="tooltipsdiv">
                                  <img src={infoIcon} alt="" />
                                  <p className="tooltip-para">The price of this container includes ${_.get(this.props, 'containerData.tons', '')} tons of material. If you go over the included amount you will be charged for the additional tonnage after the container is picked up. If you're unsure or have any questions, reach out to one of our experts who will gladly help!</p>
                                </div>
                            </span>
                          </div>
                        </li>
                      </ul>
                      {/* <div className="form-group">
                        <label htmlFor="theirName" className="services__label">Coupon Code</label>
                        <div className="couponcode">
                          <input
                            type="text"
                            placeholder="Enter coupon code "
                            name="couponCode"
                            value={this.state.couponCode}
                            onChange={this.handleCouponChange.bind(this)}
                            className="services__input" />
                          <button disabled={this.state.couponCodeDisabled} onClick={this.applyCouponCode.bind(this)} className="btn btn__apply">Apply</button>
                        </div>
                        <span className="errors">{this.state.couponCodeError ? this.state.couponCodeError : ""}</span>
                      </div> */}
                      <div className="checkboxlabel">
                          <label htmlFor="term" className="custom-checkbox custom-checkbox-line">
                            <input id="htm2" type="checkbox" readOnly onClick={this.onClick.bind(this)} checked={this.state.terms} className="custom-checkbox--input" />
                          By clicking Book, you agree to our <span><a href={'/terms'}  target="_blank">Terms and Conditions</a></span>
                            <span onClick={this.onClick.bind(this)} className="checkmark"></span>
                          </label>
                      </div>
                      <MyCustomButton validate={() => this.validate()} state={this.state} disabled={this.nextDisabled()}/>
                    </div>
                    </SquarePaymentForm>
                  :
                  <AddCardknoxForm
                    nextDisabled={this.nextDisabled}
                    onClick={(e) => this.onClick(e)}
                    terms={this.state.terms}
                    expDate={this.state.expDate}
                    onChange={this.handleChange.bind(this)}
                    setCardNumberIsValid={(cardNumberIsValid) => this.setState({ cardNumberIsValid })}
                    state={this.state}
                    bookOrder={(data) => this.bookOrder(data)}
                    xKey={this.state.xKey}
                    expDateMask={expDateMask}
                  />
                  }
                </div>

                <div className="byclicking">
                    By clicking submit, your booking will be sent to the Curbside team for review. The provided credit card will not be charged the amount
                    above until the Curbside team approves your order. If we are unable to approve with the information provided here, you will be contacted
                    by a Curbside team member to discuss any necessary adjustments required.
                </div>

                <div className="left__section--inner">
                    <div className="heading">Frequently Asked Questions</div>
                    <Collapse
                      accordion
                      bordered={false}
                      showArrow={false}
                      className="faq-list"
                    >
                      {/* <Panel
                        header="Does my bin have to be completely full before you will pick it up?"
                        key="1"
                      >
                        <p className="para">
                          We will definitely do the best we can! The schedule is
                          routed daily and always changing. We always take all
                          requests into consideration but sometimes we aren’t always
                          able to arrive at the exact time. We will always notify
                          you of when you can expect your debris removal service.
                    </p>
                      </Panel>
                      <Panel
                        header="When do I have to pay?"
                        key="2"
                      >
                        <p></p>
                      </Panel>
                      <Panel
                        header="How do I schedule a pickup?"
                        key="3"
                      >
                        <p></p>
                      </Panel> */}

                      <Panel
                        header="Are there any materials that are not allowed in the container?"
                        key="4"
                      >
                        <p className="pad-add">No 55 gallon drums empty or full, car batteries, asbestos or asbestos containing products, hazardous materials (liquids such as paint, oil, solvent, pesticides, etc.), CFC containing appliances (such as air conditioners, refrigerators, freezers, de-humidifiers, etc.), and no car tires are allowed</p>
                      </Panel>

                      <Panel
                        header="Can you deliver my container at a specific time?"
                        key="5"
                      >
                        <p className="pad-add">We will definitely do the best we can! The schedule is routed daily and always changing. We always take all requests into consideration but sometimes we aren’t always able to arrive at the exact time. We will always notify you of when you can expect your debris removal service.</p>
                      </Panel>

                      </Collapse>
                </div>
              </div>
              </div>
            </div>
          </div>
          <ReactModal
            isOpen={this.state.openModal}
            onRequestClose={this.closeModal.bind(this)}
            contentLabel=""
            ariaHideApp={false}
            className="paymentlogin"
            >
              <div className="react-modal-dialog react-modal-dialog-400 react-modal-dialog-centered">
                <div className="react-modal-header">
                  {/* <h5 className="react-modal-title"></h5> */}
                <button type="button" className="btn react-modal-close" onClick={this.closeModal.bind(this)}><img src={modalcloseIcon} alt="" /></button>
                </div>
                <div className="react-modal-body">
                  <div className="wrapps">
                    <h4>You already have an account, please login to you portal to place an order.</h4>
                    <a href={LOGIN_URL} target="_blank"><button className="login-btn">Login</button></a>
                  </div>
                </div>
            </div>
          </ReactModal>
        </section>
      </div>
    );
  }
}

export default PaymentPage;
