import React from 'react';
import _ from 'lodash';
import IField, { CARD_TYPE } from 'react-cardknox-ifields';
import { Spin, Icon } from "antd";

import InputMask from 'react-text-mask'
import lockIcon from "../../../images/lockicon.svg"
const base64 = require("base-64");
const antIcon = <Icon type="loading" style={{ fontSize: 20 }} spin />;

export default class App extends React.Component {
  constructor(props) {
    super(props);
    const account = {
      xKey: '',
      xSoftwareName: 'react-cardknox-ifields',
      xSoftwareVersion: '1.0.0',
    };
    const threeds = {
      enable3DS: false,
      waitForResponse: false,
      waitForResponseTimeout: undefined,
      amount: 0,
      month: '01',
      year: '2020',
    };
    const ccoptions = {
      placeholder: 'Card Number',
      enableLogging: true,
      autoFormat: true,
      autoFormatSeparator: ' ',
      autoSubmit: false,
      iFieldstyle: { border: '1px solid #979797', height:'54px', width:'95%', borderRadius:'4px', padding:'0 14px', fontSize: '16px'},
      iFrameStyle: { border: '0', width: '100%', height: '60px', position: 'relative' },
      iFrameClassName: 'ifieldiframe',
    };
    const monthOptions = {
      placeholder: 'Month',
      enableLogging: true,
      autoFormat: true,
      autoFormatSeparator: ' ',
      autoSubmit: false,
      iFieldstyle: { border: '1px solid #979797', height: '54px', width: '85%', borderRadius: '4px', padding: '0 15px' },
      iFrameStyle: {},
      iFrameClassName: 'ifieldiframe',
    };
    this.state = {
      account,
      threeds,
      ccoptions,
      monthOptions,
      issuer: '',
      xToken: '',
      expDate: '',
      cardNumberIsValid: false,
      err: {},
      btnDisabled: false,
      iFrameLoaded: false
    };
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount () {
    const { xKey } = this.props
    const { account } = this.state
    if (xKey) {
      const iFieldKey = base64.decode(xKey)
      this.setState({ account: { ...account, xKey: iFieldKey }})
    }
  }

  setAsyncState = (newState) => new Promise((resolve) => this.setState(newState, resolve));

  async validate() {
    let errObj = {}
    const { cardNumberIsValid, expDate } = this.state
    if (!cardNumberIsValid) {
      errObj.cardNumber = 'Enter valid card number'
    }
    const expDateFormat = this.state.expDate.replace('/', '')
    if (expDate === '' || expDateFormat.length !== 4) {
      errObj.expDate = 'Enter valid expiration date'
    }
    await this.setAsyncState({ err: errObj })
  }

  onHandleChange (e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  onLoad = () => {
    this.setState({ iFrameLoaded: true })
  };
  onUpdate = (data) => {
    console.log("Iframe Updated", data);
    this.setState({ issuer: data.issuer }, () => {
      this.props.setCardNumberIsValid(data.isValid)
    });
  };
  onSubmit = (data) => {
    console.log('IFrame submitted', data);
  };
  onToken = async({ xToken }) => {
    console.log('IFrame sent token', xToken);
    this.setState({ xToken })
  };

  submit = async() => {
    const { xToken } = this.state
    const { email, firstname, lastname, phone, expDate, theirPhone, theirName, cardnumber, nameOncard, securityCode, couponCode, pointOfContact } = this.props.state
    const data = {
      email, firstname, lastname, theirName, cardnumber, nameOncard, securityCode,
      expDate: parseInt(expDate.replace('/', '')),
      phone: phone.replace(/[^0-9]/g,""),
      theirPhone: theirPhone.replace(/[^0-9]/g,""),
      couponCode, pointOfContact,
      name: firstname + " " + lastname
    }
    data.id = xToken
    data.stripeData = {}
    this.setState({ btnDisabled: true })
    await this.props.bookOrder(data)
  }

  closeModal() {
    this.props.closeCardModal()
  }

  onError = (data) => {
    console.error('IFrame errored', data);
  };

  render() {
    const { err, btnDisabled, iFrameLoaded } = this.state
    return (
      <>
        <div className="info__section border__none">
					<h4>Payment</h4>
					<p>We use Cardknox to securely store and process your payment info. <br />
				All transacation are secure and encrypted
				</p>
        <div htmlFor="cardnumber" className="form-group posi__relative">
          <label className="services__label">Card Number</label>
          <IField
							type={CARD_TYPE}
							account={this.state.account}
							options={this.state.ccoptions}
							threeDS={this.state.threeds}
							issuer={this.state.issuer}
							onLoad={this.onLoad}
							onUpdate={this.onUpdate}
							onSubmit={this.onSubmit}
							onToken={this.onToken}
							onError={this.onError}
							src="https://cdn.cardknox.com/ifields/2.5.1905.0801/ifield.htm"
						/>
            <span className="info__mark_ifield info__mark_ifield-new"><img src={lockIcon} alt="" /></span>
					<span className="errors">{err && err.cardnumber ? err.cardnumber : ""}</span>
        </div>
					<div htmlFor="cardnumber" className="form-group posi__relative">
						<label className="services__label">Expiration Date (MM/YY)</label>
						<InputMask
							guide={false}
							type="text"
							keepCharPositions={false}
							mask={this.props.expDateMask}
              className="form-control material-textfield-input services__input"
							name="expDate"
							placeholder="(MM/YY)"
							value={this.props.expDate}
							onChange={this.props.onChange}
							required />
					<span className="info__mark"><img src={lockIcon} alt="" /></span>
					<span className="errors">{err && err.expDate ? err.expDate : ""}</span>
					</div>
					<div className="checkboxlabe mb-3">
						<label htmlFor="term" className="custom-checkbox custom-checkbox-line">
							<input id="htm2" readOnly onClick={(e) => this.props.onClick(e)} checked={this.props.terms} className="custom-checkbox--input" type="checkbox" />
						By clicking Book, you agree to our <span><a href={'/terms'}  target="_blank">Terms and Conditions</a></span>
							<span onClick={(e) => this.props.onClick(e)} className="checkmark"></span>
						</label>
					</div>
					<button disabled={this.props.nextDisabled()} onClick={()=>{this.submit()}} /*onClick={this.submit.bind(this)}*/ className="btn btn-complete">Complete Booking</button>
				</div>
				</>
    );
  }
}
